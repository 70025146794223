import Router from 'next/router'
import styled from 'styled-components'
import media from '../styles/media'
import ScrollableModal from '../components/ScrollableModal'
import { useState, useEffect } from 'react'

import { getCategories } from '../service/contentfulAPIs'

export default function CTAModal({ modalOpen, setModalOpen }) {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const getAndSetCategories = async () => {
      const categories = await getCategories()
      const availableCategories = categories.filter((category) => !category.isHidden)
      setCategories(availableCategories)
    }
    getAndSetCategories()
  }, [])

  const handleRedirect = (path) => {
    setModalOpen(false)
    Router.push(path)
  }

  return (
    <Content
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      closeWhileClickOutside={true}
      fixedHeader={{
        title: '開始製作',
        hasCloseBtn: true,
      }}
    >
      <Options>
        {categories.map((category, index) => (
          <Option key={index} onClick={() => handleRedirect(`/categories/${category.id}`)}>
            <OptionImg src={category.thumbnail}></OptionImg>
            <h5>{category.name}</h5>
          </Option>
        ))}
      </Options>
    </Content>
  )
}

const Content = styled(ScrollableModal)`
  width: auto;
  min-width: 400px;
  text-align: center;
  h3 {
    font-size: 24px;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 32px;
  }
  ${media.md`
      min-width: unset;
    `}
`
const Title = styled.div`
  position: relative;
  img {
    position: absolute;
    right: 0;
    top: 4px;
    cursor: pointer;
  }
`
const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  aling-items: center;
  justify-content: center;
  column-gap: 24px;
  row-gap: 24px;
  ${media.md`
      grid-template-columns: 1fr 1fr;
    `}
`
const Option = styled.div`
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  img {
    width: 128px;
    height: 128px;
    object-fit: contain;
  }
  h5 {
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 24px;
  }
  &:hover {
    background-color: var(--grayInput);
  }
`
const OptionImg = styled.div`
  width: 100%;
  max-width: 128px;
  &:after {
    content: ' ';
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    ${(props) => `background-image: url(${props.src});`}
  }
`
