import simpleFetchAPI from '../utils/simpleFetchAPI'

const baseUrl = process.env.contentfulUrl
const accessToken = process.env.contentfulAccessToken

const getEntryEndpoint = (entryId) => {
  const url = `${baseUrl}/entries/${entryId}?access_token=${accessToken}`
  return url
}
const getEntriesEndpoint = (contentType) => {
  const url = `${baseUrl}/entries?access_token=${accessToken}&content_type=${contentType}`
  return url
}

const getAssetEndpoint = (assetId) => {
  const url = `${baseUrl}/assets/${assetId}?access_token=${accessToken}`
  return url
}
const getAssetsEndpoint = (limit = 300) => {
  const url = `${baseUrl}/assets?access_token=${accessToken}&limit=${limit}`
  return url
}

const getResListByIds = async (iterable) => {
  const ids = iterable.map((e) => e.sys.id)

  const tempList = ids.map(async (id) => {
    const apiUrl = getEntryEndpoint(id)
    const res = await simpleFetchAPI(apiUrl)

    return res
  })

  const resList = await Promise.all(tempList).then((values) => values)

  return resList
}

export { getEntryEndpoint, getEntriesEndpoint, getAssetEndpoint, getAssetsEndpoint, getResListByIds }
