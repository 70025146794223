import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { getEntryEndpoint, getEntriesEndpoint, getAssetEndpoint, getAssetsEndpoint, getResListByIds } from './contentfulUtils'
import simpleFetchAPI from '../utils/simpleFetchAPI'

const getCategory = async (categoryId) => {
  try {
    const url = getEntryEndpoint(categoryId)
    const res = await simpleFetchAPI(url)

    return {
      id: categoryId,
      name: res.fields.name,
      isHidden: res.fields.hidden,
      metaTitle: res.fields.metaTitle,
      metaDescription: res.fields.metaDescription,
      slug: res.fields.slug,
    }
  } catch (err) {
    console.error('err is: ', err)
    return {}
  }
}
const getCategories = async () => {
  const url = getEntriesEndpoint('productType&order=-sys.updatedAt')
  const res = await simpleFetchAPI(url)

  const assetsEndpoint = getAssetsEndpoint()
  const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

  const tempCategories =
    res?.items?.map((category) => {
      return {
        id: category.sys.id,
        name: category.fields.name,
        isHidden: category.fields.hidden,
        thumbnail: assets.find((asset) => asset.sys.id === category.fields.thumbnail.sys.id).fields.file.url,
        description: category.fields.description,
        metaTitle: category.fields.metaTitle,
        metaDescription: category.fields.metaDescription,
        slug: category.fields.slug,
      }
    }) || []

  return tempCategories
}

const getProduct = async (productName) => {
  try {
    const productsUrl = `${getEntriesEndpoint('product')}`
    const products = await simpleFetchAPI(productsUrl)
    let product = products.items.find((p) => p.fields.slug === productName)
    // for compatible with product ID version
    if (!product) {
      const productUrl = getEntryEndpoint(productName)
      product = await simpleFetchAPI(productUrl)
    }

    const categoryId = product.fields.productCategory.sys.id
    const categoryUrl = getEntryEndpoint(categoryId)
    const categoryRes = await simpleFetchAPI(categoryUrl)
    console.log('categoryRes is: ', categoryUrl, categoryRes)
    const isProductCategoryHidden = categoryRes.fields.hidden

    let templateWidth = null
    let templateHeight = null
    // priceType：計價方式
    const priceTypeIds = product.fields.priceTypes.map((sysObj) => sysObj.sys.id)
    const tempPriceTypesFromRes = priceTypeIds.map(async (id) => {
      const priceTypeUrl = getEntryEndpoint(id)
      const priceType = await simpleFetchAPI(priceTypeUrl)

      return priceType
    })
    const priceTypesFromRes = await Promise.all(tempPriceTypesFromRes).then((values) => values)

    console.log(
      'priceTypesFromRes is: ',
      priceTypesFromRes.map((e) => e.sys.contentType.sys.id),
    )
    // console.log('priceTypesFromRes is: ', priceTypesFromRes.map(e => e.fields.data))
    const priceTypes = priceTypesFromRes.map((e) => e.sys.contentType.sys.id)

    const priceOfFormat = priceTypesFromRes.find((e) => e.sys.contentType.sys.id === 'priceOfFormat')
    console.log('format: ', priceOfFormat)
    if (priceOfFormat) {
      templateWidth = priceOfFormat.fields.width
      templateHeight = priceOfFormat.fields.height
    }

    // priceTypes:
    // priceOfQuantity
    // priceOfArea
    // priceOfFormat：公版印刷
    // priceOfSize

    let sizesId
    if (priceTypes.includes('priceOfQuantity') || priceTypes.includes('priceOfSize')) {
      // TODO: 不 call
      // priceOfQuantity 直接顯示 可選數量列表
      // priceOfSize 拿 priceType 顯示
      // sizesId = product.fields.countSelectTable.sys.id
    } else {
      console.log('product.fields.sizeSelectTable is: ', product.fields.sizeSelectTable)
      sizesId = product.fields.sizeSelectTable.sys.id
    }

    // const sizesId = product.fields.countSelectTable.sys.id
    // TODO: 除了數量計價以外都會有 sizeSelectTable，數量計價待討論怎麼處理
    // const sizesId = product.fields.countSelectTable.sys.id
    // const sizesId = product.fields.sizeSelectTable.sys.id
    const sizesUrl = getEntryEndpoint(sizesId)
    let sizesRes
    if (priceTypes.includes('priceOfQuantity') || priceTypes.includes('priceOfSize')) {
    } else {
      sizesRes = await simpleFetchAPI(sizesUrl)
    }

    if (priceTypes.includes('priceOfQuantity')) {
      // TODO: 應該是拿 countSelectTable 去 fetch
      // const sizesId = product.fields.countSelectTable.sys.id
    }

    console.log('sizesRes is: ', sizesRes)
    let sizes = []
    if (priceTypes.includes('priceOfQuantity') || priceTypes.includes('priceOfSize')) {
    } else {
      sizes = Object.keys(sizesRes.fields.list).map((sizeKey) => ({
        name: sizeKey,
        width: sizesRes.fields.list[sizeKey][0],
        height: sizesRes.fields.list[sizeKey][1],
      }))
    }
    if (priceTypes.includes('priceOfSize')) {
      console.log('priceTypesFromRes.fields is: ', priceTypesFromRes[0].fields.data)
      sizes = Object.keys(priceTypesFromRes[0].fields.data).map((sizeKey) => ({
        name: sizeKey,
        sizeAttributeName: sizeKey,
        width: priceTypesFromRes[0].fields.data[sizeKey].size[0],
        height: priceTypesFromRes[0].fields.data[sizeKey].size[1],
      }))
    }

    const productDescription = documentToHtmlString(product.fields.description)

    const assetsEndpoint = getAssetsEndpoint()
    const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

    const thumbnailUrl = assets.find((asset) => asset.sys.id === product.fields.thumbnail.sys.id).fields.file.url
    console.log('thumbnailUrl is: ', thumbnailUrl)

    const imageUrls = product.fields.images
      .map((imageInfo) => assets.find((asset) => asset.sys.id === imageInfo.sys.id))
      .map((asset) => asset.fields.file.url)

    let additions = []
    console.log('product.fields.additions is: ', product.fields.additions)
    if (product.fields.additions) {
      const additionsFromRes = await getResListByIds(product.fields.additions)
      const additionPromises = additionsFromRes
        .map((addition) => ({
          id: addition.sys.id,
          ...addition.fields,
        }))
        .map(async (additionField) => {
          const id = additionField.id
          const title = additionField.title
          const type = additionField.type
          const description = documentToHtmlString(additionField.description)
          const thumbnailUrl = assets.find((asset) => asset.sys.id === additionField.thumbnail?.sys?.id)?.fields?.file?.url || ''

          console.log('additionField is: ', type, additionField)
          if (!additionField.options?.length) {
            return {}
          }

          const options = await getResListByIds(additionField.options)
          const optionsFields = options
            .map((option) => {
              const optionThumbnailUrl = assets.find((asset) => asset.sys.id === option.fields.thumbnail?.sys?.id)?.fields?.file?.url || ''
              console.log('option type is: ', option.fields.type)
              if (option.fields.type !== type) {
                return {}
              }

              return {
                // price: 30,
                // test: '3',
                ...option.fields,
                id: option.sys.id,
                name: option.fields.title,
                thumbnail: optionThumbnailUrl,
                // price: 30,
              }
            })
            .filter((option) => Object.keys(option).length)

          // fields: {
          //   title: '需要協助客戶設計白墨',
          //   description: '請直接幫我設計酷酷的白墨圖層，感謝！',
          //   fixedPrice: 0,
          //   priceTypes: [], // (optional?)，也會是一個 sys.id
          // }

          console.log('optionsFields is: ', optionsFields)

          return {
            id,
            title,
            type,
            description,
            thumbnail: thumbnailUrl,
            options: optionsFields,
          }
        })

      additions = await Promise.all(additionPromises).then((values) => values)

      additions = additions.filter((e) => Object.keys(e).length)

      // additions.reverse()

      // console.log('fieldsList is: ', additions)
    }

    const hasSample = product.fields.sampleOption
    const canCustomSize = product.fields.canCustomSize
    const sizeDescription = documentToHtmlString(product.fields.sizeSelectInfo)

    let cuttingTypes = []
    if (product.fields.cuttingType) {
      const cuttingTypesFromRes = await getResListByIds(product.fields.cuttingType)
      console.log('cuttingTypesFromRes is: ', cuttingTypesFromRes)
      cuttingTypes = cuttingTypesFromRes
        .map((cuttingType) => ({
          id: cuttingType.sys.id,
          ...cuttingType.fields,
        }))
        .map((cuttingType) => {
          const iconUrl = assets.find((asset) => asset.sys.id === cuttingType.icon?.sys?.id)?.fields?.file?.url || ''
          const thumbnailUrl = assets.find((asset) => asset.sys.id === cuttingType.thumbnail?.sys?.id)?.fields?.file?.url || ''
          const description = documentToHtmlString(cuttingType.description)

          return {
            ...cuttingType,
            icon: iconUrl,
            thumbnail: thumbnailUrl,
            description,
          }
        })
    }

    console.log('will return pro')
    return {
      id: product.sys.id,
      categoryId,
      isProductCategoryHidden,
      title: product.fields.title,
      subtitle: product.fields.subTitle,
      sizes,
      description: productDescription,
      thumbnail: thumbnailUrl,
      images: imageUrls,
      tags: product.fields.tags || [],
      cuttingMethods: cuttingTypes,
      templateWidth,
      templateHeight,

      additions: additions,
      priceTypes,
      hasSample,
      canCustomSize,
      sizeDescription,
      isImageUploadRequired: product.fields.imageUploadRequired,

      metaTitle: product.fields.metaTitle || '',
      metaDescription: product.fields.metaDescription || '',
      // slug: product.fields.slug || '',
    }
  } catch (err) {
    console.error('err is: ', err)
    return {}
  }
}

const getProducts = async ({ category, shouldGetImages = false }) => {
  const productUrl = `${getEntriesEndpoint('product')}&links_to_entry=${category}&order=-sys.createdAt`
  const prodRes = await simpleFetchAPI(productUrl)

  return prodRes
}

const getProductsByFilter = async (category, filter) => {
  const tags = filter.tags.reduce((acc, tag) => (acc === '' ? tag.sys.id : `${acc},${tag.sys.id}`), '')
  const productUrl = `${getEntriesEndpoint('productType')}&sys.id=${category}&order=-sys.createdAt`
  const prodRes = await simpleFetchAPI(productUrl)
  if (!prodRes.items || prodRes.items.length == 0) return []

  let result = []
  prodRes.items[0].fields.products.map((item) => {
    const target = prodRes.includes.Entry.find((e) => e.sys.id == item.sys.id)
    if (!target) return
    if (!target.fields.tags.find((tag) => filter.tags.find((f) => f.sys.id == tag.sys.id))) return
    result.push(target)
  })

  return result
}

const getRecommendProducts = async ({ limit = 12 }) => {
  const rID = process.env.recommendId // 推薦項目 ID
  const productUrl = `${getEntriesEndpoint('product')}&fields.tags.sys.id[in]=${rID}&order=-sys.createdAt`
  const prodRes = await simpleFetchAPI(productUrl)

  const assetsEndpoint = getAssetsEndpoint()
  const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

  const products =
    prodRes.items
      .map((item, index) => {
        return {
          id: item.sys.id,
          title: item.fields.title,
          thumbnail: assets.find((asset) => asset.sys.id === item.fields.thumbnail.sys.id).fields.file.url,
        }
      })
      .sort(() => Math.random() - 0.5)
      .slice(0, limit) || []

  return products
}

const getGalleryItems = async () => {
  const url = getEntriesEndpoint('galleryItems')
  const res = await simpleFetchAPI(url)

  const assetsEndpoint = getAssetsEndpoint()
  const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

  const tempItems =
    res?.items?.map((item) => {
      return {
        id: item.sys.id,
        thumbnail: assets.find((asset) => asset.sys.id === item.fields.thumbnail.sys.id).fields.file.url,
      }
    }) || []

  return tempItems
}

const getFormattedProducts = async ({ category, shouldGetImages = false }) => {
  const productUrl = `${getEntriesEndpoint('productType')}&sys.id=${category}&order=-sys.createdAt`
  const res = await simpleFetchAPI(productUrl)
  if (!res.items || res.items.length == 0) return []
  const prodRes = res.items[0].fields.products.map((p) => res.includes.Entry.find((e) => e.sys.id == p.sys.id))

  const tempProducts = prodRes.map(async (product) => {
    const productDescription = documentToHtmlString(product.fields.description)

    let thumbnailUrl
    let imageUrls = []
    if (!shouldGetImages) {
      const assetEndpoint = getAssetEndpoint(product.fields.thumbnail.sys.id)

      thumbnailUrl = await fetch(assetEndpoint)
        .then((r) => r.json())
        .then((res) => res.fields.file.url)
    } else {
      const assetsEndpoint = getAssetsEndpoint()
      const assets = await fetch(assetsEndpoint)
        .then((r) => r.json())
        // .then(res => res.items.map(item => item.sys.id))
        .then((res) => res.items)

      thumbnailUrl = assets.find((asset) => asset.sys.id === product.fields.thumbnail.sys.id).fields.file.url

      imageUrls = product.fields.images
        .map((imageInfo) => assets.find((asset) => asset.sys.id === imageInfo.sys.id))
        .map((asset) => {
          console.log('asset is: ', asset)
          return asset.fields.file.url
        })
    }

    // console.log('thumbnailUrl is: ', thumbnailUrl)
    // console.log('imageUrls is: ', imageUrls)

    return {
      id: product.sys.id,
      title: product.fields.title,
      subtitle: product.fields.subTitle,
      description: productDescription,
      thumbnail: thumbnailUrl,
      images: imageUrls,
      tags: product.fields.tags || null,
    }
  })

  const result = await Promise.all(tempProducts).then((values) => values)
  // console.log('tempProducts is: ', tempProducts)
  // array filled with promise

  return result
}

const getFilters = async ({ category }) => {
  const filterUrl = `${getEntriesEndpoint('filter')}&links_to_entry=${category}`
  const filterRes = await simpleFetchAPI(filterUrl)

  return filterRes
}

const getFaqSections = async () => {
  const sectionsUrl = `${getEntriesEndpoint('faqCategory')}&order=sys.createdAt`
  const sectionsRes = await simpleFetchAPI(sectionsUrl)

  const data = Promise.all(
    await sectionsRes.items.map(async (item) => {
      return {
        section: item,
        questions: await item.fields.questions.map((q) => sectionsRes.includes.Entry.find((e) => e.sys.id == q.sys.id)),
      }
    }),
  )

  return sectionsRes ? data : []
}

const getFaqBySection = async ({ section }) => {
  const faqUrl = `${getEntriesEndpoint('faqCategory')}`
  const faqRes = await simpleFetchAPI(faqUrl)

  let faqInfo = faqRes.items.find((res) => res.fields.slug === section)
  if (!faqInfo) {
    const faqUrl = `${getEntriesEndpoint('faqCategory')}&sys.id=${section}`
    const faqRes = await simpleFetchAPI(faqUrl)

    faqInfo = faqRes.items[0]
  }
  const questions = faqInfo ? faqInfo.fields.questions.map((q) => faqRes.includes.Entry.find((e) => e.sys.id == q.sys.id)) : null

  return {
    questions: questions,
    section: faqInfo || null,
  }
}

const getRecommendedFaq = async () => {
  const faqUrl = `${getEntriesEndpoint('faqCategory')}`
  const faqRes = await simpleFetchAPI(faqUrl)

  const data = faqRes
    ? faqRes.includes.Entry.filter((e) => e.fields.isRecommended).map((q) => {
        const section = faqRes.items.find((i) => i.fields.questions.some((qs) => qs.sys.id == q.sys.id))
        return {
          section,
          ...q,
        }
      })
    : []

  return data.sort(() => Math.random() - 0.5).slice(0, 10)
}

const getFaq = async ({ question }) => {
  const questionsUrl = `${getEntriesEndpoint('faq')}`
  const questionsRes = await simpleFetchAPI(questionsUrl)
  let faqId = questionsRes.items.find((res) => res.fields.slug === question)?.sys?.id

  const faqUrl = `${getEntriesEndpoint('faqCategory')}&links_to_entry=${faqId || question}&include=10`
  const faqRes = await simpleFetchAPI(faqUrl)

  return {
    question: faqRes ? faqRes.includes.Entry.find((q) => q.sys.id === faqId || q.sys.id === question) : [],
    section: faqRes.items.length ? faqRes.items[0] : null,
    includes: faqRes ? faqRes.includes : [],
  }
}

export {
  getCategory,
  getCategories,
  getProduct,
  getProducts,
  getFormattedProducts,
  getFilters,
  getFaqSections,
  getFaqBySection,
  getRecommendedFaq,
  getFaq,
  getGalleryItems,
  getRecommendProducts,
  getProductsByFilter,
}
